<template>
    <div class="w-100 h-100">
        <b-card class="mb-3 w-100 h-100">
            <b-overlay :show="loading_list" :opacity="0.0">
                <app-view-table :title="trans('background-workers',203)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :isPerPageSelect="false" :customColumns="customColumns" :items="items" :isDropdownMenu="false" :fields="fields" :isSearch="true">
                    <template v-slot:status_name="cell">
                        <span v-bind:class="{ dotActive: cell.data.item.status == 1, dotInactive: cell.data.item.status == 2, dotWarning: cell.data.item.status == 3}" data-toggle="tooltip" :title="cell.data.item.status_name"> </span>
                    </template>
                </app-view-table>
             <template #overlay>
                    <div></div>
                </template>
            </b-overlay>
        </b-card>

    </div>
</template>

<script>

import axios from "@axios";
import UserSettings from '@core/scripts/UserSettings';
export default {
    props:[],
    
    mounted() {
      this.onCreateThis();
    },

    data() {
        return {
            customColumns: ["status_name"],
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {},
                page: 1,
                per_page: 500000,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields: [
                { key: "type", label: this.trans('type',182), thStyle: { "min-width": "350px", "max-width": "350px" }, visible: true},
                { key: "action_date", label: this.trans('action-date',203), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true  },
                { key: "frequency", label: this.trans('frequency',203), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true }, 
                { key: "description", label: this.trans('description',182), thStyle: { "min-width": "200px", width: "100%"  }, visible: true }, 
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true }, 

            ],
            items: [],
            user_settings: new UserSettings()
        };
    },
    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/users/dashboard/getBackgroundWorkersList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },
   
        saveSettings() {
            this.user_settings.saveSettings(this.filters, 22);
        },
        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));
            
            if(getData == undefined || getData.widgetBackgroundWorkersList == undefined){
                await this.user_settings.saveSettings(this.filters, 22);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
            
            var sFilters = getData.widgetBackgroundWorkersList
            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting;
        },
        onCreateThis() { 
            this.loadSettings();
            this.getList();
            this.$root.$on('locationsChanged', () => {
        
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });
            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });
            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });
            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },
    },
    
 
};
</script>

<style scoped >
.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #20dc37;
  border-radius: 50%;
  display: inline-block;
}
.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.dotWarning {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}
</style>