<template>
    <b-card>
        <b-overlay :show="loading_list" :opacity="0">
            <app-view-table :title="trans('applicants',5)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" v-on:onRowSelected="getProfile" :fields="fields" :isSearch="true" :isDropdownMenu="false" class="table-selectable">
                
                <template v-slot:buttons>
                    <app-button type="primary" :url="{ name: 'people-applicants-list'}" >{{trans('d-view-all',203)}}</app-button>
                </template>
            </app-view-table>

            <template #overlay>
                <div></div>
            </template>

        </b-overlay>
    </b-card>
</template>
<script>

// #ID  - PMI 2022-01-26
 
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';

  export default {
    
    props:["selected_office", "settings_loaded"],

    mounted() {
        this.onCreateThis(); 
    },

    data() {
        return {
            loading_list: false,
            user_settings: new UserSettings(),
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {                        
                        functions: {type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                        types: {type: 'list', changed: 0, values: [], function: "getApplicantTypes", parameters: null, widthClass:'app-width-min-250'},
                        statuses: {type: 'list', changed: 0, values: [], function: "getApplicantStatuses", parameters: null, widthClass:'app-width-min-250'},
                        locations: {type: 'list',  changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-250'}
                },
                page: 1,
                per_page: 5,
                search: "",
                list_type: 1,
                changed: 0,
                selected_office: null,
            },
            fields: [
                { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "80px", width: "100%" }, visible: true },
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "functions" },
                { key: "type_name", label: this.trans('agreement',3), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "types"  },                
                { key: "location_name", label: this.trans('branch',182), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "locations"  },  
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "statuses"  },              
            ],
            items: [],
            alertClass: new AppAlerts()
        };
    },

    methods: {
        getList() {
            if(this.settings_loaded === true){
                this.filters.selected_office = this.selected_office.value;
                this.loading_list = true;
                axios
                    .post("people/applicants/getList", {
                        filters: JSON.stringify(this.filters),
                    })
                    .then((res) => {                    
                        this.filters.page = res.data.page;
                        this.items = res.data.items;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
            }
        },

        getProfile: function(selectedRows) {
            if(selectedRows.length != 0 && selectedRows[0].id_status_const_type != 1){
                this.$router.push({ name: "people-person-profile", params: { id_person_data_main: selectedRows[0].id_person_data_main } });
            }
        },

        saveSettings() {
            this.user_settings.saveSettings(this.filters, 5);
        },

        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));
            
            if(getData == undefined || getData.widgetApplicantsList == undefined){
                await this.user_settings.saveSettings(this.filters, 5);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
              
            var sFilters = getData.widgetApplicantsList

            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting;
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 5;  
        },

        onCreateThis() { 
       
            this.loadSettings();

            this.getList();
            this.fields.filter(field => field.visible)

            this.$root.$on('locationsChanged', () => {
        
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });
            this.$watch('selected_office', function(newVal, oldVal) {
                this.getList();
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });
           
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
        },

    },
  };
</script>
<style></style>
