<template>
    <b-card class="h-100 app-local-height" no-body>
        
            <b-row class="app-local-pointer">
            
                <b-col class="app-local-right-border" @click="getList(2)">

                    <div class="app-local-first-line w-100">
                        <span class="app-local-first-line-span text-center"  v-bind:class="{ 'text-muted': statuses[0].people_count && statuses[0].people_count == 0}">
                            {{!loading ? statuses[0].people_count : 0}}                   
                        </span>
                        <b-icon icon="person-lines-fill" class="app-local-first-line-icon"  v-bind:class="{ 'text-muted': statuses[0].people_count && statuses[0].people_count == 0}"/>
                    </div>

                    <div class="w-100 text-center"> 
                        <div class="app-local-second-line w-100 text-center text-muted">
                            Gegevens<br>verzamelen
                        </div>
                    </div>
                        
                </b-col>

                <b-col class="app-local-right-border" @click="getList(3)">
                        
                    <div class="app-local-first-line w-100">
                        <span class="app-local-first-line-span text-center"  v-bind:class="{ 'text-muted': statuses[1].people_count && statuses[1].people_count == 0}">
                            {{!loading ? statuses[1].people_count : 0}}                  
                        </span>
                        <b-icon icon="ui-checks" class="app-local-first-line-icon"  v-bind:class="{ 'text-muted': statuses[1].people_count && statuses[1].people_count == 0}"/>
                    </div>

                    <div class="w-100 text-center">
                        <div class="app-local-second-line w-100 text-center text-muted">
                            Offerte
                        </div>
                    </div>                        
                        
                </b-col>
                <b-col class="app-local-right-border" @click="getList(4)">

                    <div class="app-local-first-line w-100">
                        <span class="app-local-first-line-span text-center"  v-bind:class="{ 'text-muted': statuses[2].people_count && statuses[2].people_count == 0}">
                            {{!loading ? statuses[2].people_count : 0}}                   
                        </span>
                        <b-icon icon="file-earmark-text" class="app-local-first-line-icon"  v-bind:class="{ 'text-muted': statuses[2].people_count && statuses[2].people_count == 0}"/>
                    </div>

                    <div class="w-100 text-center">
                        <div class="app-local-second-line w-100 text-center text-muted">
                            Credit check<br>aanvragen
                        </div>
                    </div>   
                        
                </b-col>
                <b-col class="app-local-right-border" @click="getList(5)">

                    <div class="app-local-first-line w-100">
                        <span class="app-local-first-line-span text-center" v-bind:class="{ 'text-muted': statuses[3].people_count && statuses[3].people_count == 0}">
                            {{!loading ? statuses[3].people_count : 0}}                  
                        </span>
                        <b-icon icon="file-earmark-check" class="app-local-first-line-icon"  v-bind:class="{ 'text-muted': this.statuses[3].people_count && statuses[3].people_count == 0}"/>
                    </div>

                    <div class="w-100 text-center">
                        <div class="app-local-second-line w-100 text-center text-muted">
                            Credit<br>check
                        </div>
                    </div> 
                        
                </b-col>
                <b-col @click="getList(7)">

                    <div class="app-local-first-line w-100">
                        <span class="app-local-first-line-span text-center" v-bind:class="{ 'text-muted': statuses[4].people_count && statuses[4].people_count == 0}">
                            {{!loading ? statuses[4].people_count : 0}}                  
                        </span>
                        <b-icon icon="file-earmark-check" class="app-local-first-line-icon"  v-bind:class="{ 'text-muted': this.statuses[4].people_count && statuses[4].people_count == 0}"/>
                    </div>

                    <div class="w-100 text-center">
                        <div class="app-local-second-line w-100 text-center text-muted">
                            Contract<br>verstuurd
                        </div>
                    </div> 
                        
                </b-col>
            </b-row>
              
    </b-card>
</template>

<script>

export default {

    data() {
        return {
            
        };
    },

    props: {
        statuses: {}, 
        loading: {}, 

    },

    methods: {
        getList(status){
            this.$router.push({ name: "clients-prospects-list", params: { status: status } });
        }
    }
};
</script>

<style scoped>
.app-local-container {
    display: flex;
    flex-wrap: nowrap;    
}

.app-local-right-border {
     border-right: 1px solid #e6e6e6 !important;   
}

.app-local-right-card {
    flex: auto;
    border-radius: 0px 5px 5px 0px;
    padding: 4px 20px 4px 20px;
    display: flex;
    align-items: stretch;
}

.app-local-title-span {
    margin-top: 20px;
    flex: auto;    
}

.app-local-progress {
    max-height: 4px; 
    min-height: 4px;
    margin-bottom: 20px;
}

.app-local-first-line {
    
    margin-top: 10px;
    margin-left: 6px;
    flex: auto;
    display: flex;
    justify-content: center;
}

.app-local-first-line-span {
    align-self: flex-end;
    font-size: 40px;
    font-weight: 600;    
}

.app-local-first-line-icon {
    align-self: flex-start;    
    margin-top: 8px;
    margin-left: 4px;
    width: 16px;
    height: 16px;    
}

.app-local-second-line {
    height: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.1;
}

.app-local-height{
    min-height: 110px !important;
}

.app-local-pointer{
    cursor: pointer !important;
}

</style>
