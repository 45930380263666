<template>
    <div class="h-100 app-local-pointer app-local-height" @click="getCompliancyList()">
        <div class="h-100 app-local-container">
            <b-card class="app-local-left-card " no-body bg-variant="secondary">
                <div class="app-local-first-line w-100">
                    <span class="app-local-first-line-span text-center">
                        {{!loading ? compliancy.invalid_flexworkers : 0}}                
                    </span>                    
                </div>
                <span class="app-local-second-line w-100 text-center">
                    van {{!loading ? compliancy.total_flexwerkers : 0}} 
                </span>               
            </b-card>
            <b-card  class="app-local-right-card" no-body>
                <span class="app-local-title-span">
                  Flexwerkers data compliance
                </span>
                <b-progress color="#066791" :value="compliancy.invalid_flexworkers" :max="compliancy.total_flexwerkers" class="w-100" variant="secondary" />
            </b-card>
        </div>  
    </div>
</template>

<script>

export default {

    data() {
        return {
        };
    },

    props: {
        compliancy: {}, 
        loading: {}, 
    },

    methods: {
        getCompliancyList() {
            this.$router.push({ name: "dashboard-compliancy-check" });
        },
    }
};
</script>

<style scoped>



.app-local-container {
    display: flex;
    flex-wrap: nowrap;    
}

.app-local-left-card {   
    max-width: 100px; 
    min-width: 100px;
    border-radius: 5px 0px 0px 5px;
}

.app-local-right-card {
    flex: auto;
    border-radius: 0px 5px 5px 0px;
    padding: 4px 20px 4px 20px;
    display: flex;
    align-items: stretch;
    
}

.app-local-title-span {
    margin-top: 20px;
    flex: auto;    
}


.app-local-first-line {
    
    margin-top: 10px;
    flex: auto;
    color: white;
    display: flex;
    justify-content: center;
}

.app-local-first-line-span {
    align-self: flex-end;
    font-size: 40px;
    font-weight: 600;    
}

.app-local-first-line-icon {
    align-self: flex-start;
    margin-top: 8px;
    margin-left: 4px;
    width: 16px;
    height: 16px;    
}

.app-local-second-line {
    max-height: 20px; 
    min-height: 20px;
    margin-bottom: 20px;
    color: white;
}

.app-local-pointer{
    cursor:pointer;
}

.app-local-height{
    min-height: 110px !important;
}
</style>
