<template>
    <div>
        <b-row class="mb-0">
            <b-col cols="11" class="p-2">
                <dashboards-clients-statuses-widget :loading="loading" :statuses="statuses"/>
            </b-col>
             <b-col cols="1" class="p-2">
                <dashboards-actions-widget :loading="loading" :actions="actions"/>
            </b-col>
        </b-row>
        <b-row class="mb-0 mt-0">
            <b-col class="p-2">
                <dashboards-prospects-list-widget :selected_office="selected_office" :settings_loaded="settings_loaded"/>
            </b-col>
        </b-row>
        <b-row class="mb-0 mt-0">
            <b-col class="p-2">
                <dashboards-clients-list-widget :selected_office="selected_office" :settings_loaded="settings_loaded"/>
            </b-col>
        </b-row>
    
    </div>
</template>

<script>
import DashboardsClientsStatusesWidget from './Widgets/Sale/DashboardsClientsStatusesWidget.vue';
import DashboardsActionsWidget from './Widgets/DashboardsActionsWidget.vue';
import DashboardsProspectsListWidget from './Widgets/Sale/DashboardsProspectsListWidget.vue';
import DashboardsClientsListWidget from './Widgets/Sale/DashboardsClientsListWidget.vue';
import axios from "@axios";

export default {
    components: {
        DashboardsClientsStatusesWidget,
        DashboardsActionsWidget,
        DashboardsProspectsListWidget,
        DashboardsClientsListWidget
    },
    
    props: {
        selected_office: {},
        settings_loaded: {type: Boolean},
    },

    watch: {
        selected_office: {
            handler: function(val) {
                this.getData();             
            },
        },
    },

    mounted(){
        this.getData();
    },

    data() {
        return {
            actions: null,
            statuses:[{people_count:0}, {people_count:0}, {people_count:0}, {people_count:0}, {people_count:0}],
            loading:false,
        };
    },
    
    methods: {
        getData(){
            if(this.settings_loaded === true){
                this.loading = true;
                axios
                    .post("settings/users/dashboard/getWidgetsDataSales", {
                        selected_office: this.selected_office.value
                    })
                    .then((res) => {
                        this.actions = res.data.actions;

                        this.statuses = [{people_count:0}, {people_count:0}, {people_count:0}, {people_count:0}, {people_count:0}];
                        res.data.statuses.forEach(item => {
                            if(item.id == 7){
                                this.statuses[4].people_count = item.people_count;
                            }else{
                                this.statuses[item.id-2].people_count = item.people_count;
                            }
                        });
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }

        },
    },
};
</script>

<style>


</style>
