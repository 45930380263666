<template>
    <div>
        <b-row class="mb-0">
            <b-col cols="3" class="p-2">
                <dashboards-compliance-widget :loading="loading" :compliancy="compliancy"/>
            </b-col>
            <b-col cols="8" class="p-2">
                <dashboards-applicants-statuses-widget :loading="loading" :statuses="statuses"/>
            </b-col>
             <b-col cols="1" class="p-2">
                <dashboards-actions-widget :loading="loading" :actions="actions"/>
            </b-col>
        </b-row>
        <b-row class="mb-0 mt-0">
            <b-col class="p-2">
               <dashboards-applicants-list-widget :selected_office="selected_office" :settings_loaded="settings_loaded"/>
            </b-col>
        </b-row>
        
        <b-row>
            <b-col class="p-2">
                <dashboards-people-list-widget :selected_office="selected_office" :settings_loaded="settings_loaded"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import DashboardsApplicantsListWidget from './Widgets/Recruitment/DashboardsApplicantsListWidget.vue';
import DashboardsPeopleListWidget from './Widgets/Recruitment/DashboardsPeopleListWidget.vue';
import DashboardsComplianceWidget from './Widgets/Recruitment/DashboardsComplianceWidget.vue';
import DashboardsActionsWidget from './Widgets/DashboardsActionsWidget.vue';
import DashboardsApplicantsStatusesWidget from './Widgets/Recruitment/DashboardsApplicantsStatusesWidget.vue';
import axios from "@axios";
export default {
    components: {
        DashboardsActionsWidget,
        DashboardsComplianceWidget,
        DashboardsApplicantsListWidget,
        DashboardsPeopleListWidget,
        DashboardsApplicantsStatusesWidget
    },

    props: {
        selected_office: {},
        settings_loaded: {type: Boolean},
    },

    watch: {
        selected_office: {
            handler: function(val) {
                this.getData();             
            },
        },
    },

    mounted(){
        this.getData();
    },

    data() {
        return {
            compliancy: {
                invalid_flexworkers:0,
                total_flexwerkers:0,
            },
           actions: null,
           statuses:[{people_count:0}, {people_count:0}, {people_count:0}, {people_count:0}],
           loading:false,
        };
    },
    
    methods: {
        getData(){
            if(this.settings_loaded === true){
                this.loading = true;
                axios
                    .post("settings/users/dashboard/getWidgetsDataRecruitment", {
                        selected_office: this.selected_office.value
                    })
                    .then((res) => {
                        this.compliancy = res.data.compliancy;
                        this.actions = res.data.actions;

                        this.statuses = [{people_count:0}, {people_count:0}, {people_count:0}, {people_count:0}];
                        res.data.statuses.forEach(item => {
                            this.statuses[item.id-2].people_count = item.people_count;
                        });
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style>


</style>
