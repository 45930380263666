<template>
    <div class="w-100 h-100">
        <b-card class="mb-3 w-100 h-100">
            <b-overlay :show="loading_list" :opacity="0.0">
                <app-view-table :title="trans('users',167)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" v-on:onRowSelected="getProfile" :isDropdownMenu="false" :fields="fields" :isSearch="true" class="table-selectable">
                    <template v-slot:buttons>
                        <app-button type="primary" :url="{ name: 'users'}" >{{trans('d-view-all',203)}}</app-button>
                    </template>
                </app-view-table>
             <template #overlay>
                    <div></div>
                </template>
            </b-overlay>
        </b-card>

    </div>
</template>

<script>

import axios from "@axios";
import UserSettings from '@core/scripts/UserSettings';
export default {
    props:[],
    
    mounted() {
      this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {},
                page: 1,
                per_page: 5,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields: [
                { key: "user_name", label: this.trans('full-name',3), thStyle: { "min-width": "80px", width: "100%" }, visible: true},
                { key: "last_event_date", label: this.trans('settings-last-event-date',207), thStyle: { "min-width": "220px", "max-width": "220px"  }, visible: true }, 

            ],
            items: [],
            user_settings: new UserSettings()
        };
    },
    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/users/dashboard/getActiveUsersList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        getProfile: function(selectedRows) {
            if(selectedRows.length != 0){
                this.$router.push({ name: "users-profile", params: { id_user: selectedRows[0].id_user } });
            }
        },
   
        saveSettings() {
            this.user_settings.saveSettings(this.filters, 23);
        },
        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));
            
            if(getData == undefined || getData.widgetActieveUsersList == undefined){
                await this.user_settings.saveSettings(this.filters, 23);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
            
            var sFilters = getData.widgetActieveUsersList
            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting;
        },
        onCreateThis() { 
            this.loadSettings();
            this.getList();
            this.$root.$on('locationsChanged', () => {
        
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });
            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });
            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });
            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },
    },
    
 
};
</script>

<style scoped >
</style>