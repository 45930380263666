<template>
    <div>
        <b-row>
            <b-col cols="12" class="p-2">
                <dashboards-admin-statuses-widget :loading="loading" :statuses="statuses"/>
            </b-col>
            <!--<b-col cols="6" class="p-2">
                <dashboards-api-statuses-widget :loading="loading" :statuses="api_statuses"/>
            </b-col>-->
        </b-row>
        <b-row>
            <b-col cols="8" class="p-2">
                <dashboards-background-workers-list-widget/>
            </b-col>
            <b-col cols="4" class="p-2">
                <dashboards-actieve-users-list-widget/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import DashboardsBackgroundWorkersListWidget from './Widgets/Administration/DashboardsBackgroundWorkersListWidget.vue';
import DashboardsActieveUsersListWidget from './Widgets/Administration/DashboardsActieveUsersListWidget.vue';
import DashboardsAdminStatusesWidget from './Widgets/Administration/DashboardsAdminStatusesWidget.vue';
import DashboardsApiStatusesWidget from './Widgets/Administration/DashboardsApiStatusesWidget.vue';
import axios from "@axios";

export default {
    components: {
        DashboardsBackgroundWorkersListWidget,
        DashboardsActieveUsersListWidget,
        DashboardsAdminStatusesWidget,
        DashboardsApiStatusesWidget
    },

    props: {
        selected_office: {},
    },
    
    mounted(){
        this.getData();
    },

    data() {
        return {
            statuses:{},
            api_statuses:{},
            loading: false

        };
    },
    
    methods: {
        getData(){
            this.loading = true;
            axios
                .get("settings/users/dashboard/getWidgetsDataAdmins")
                .then((res) => {
                    console.log(res.data)
                    this.statuses = res.data.statuses
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style>


</style>
