<template>
    <b-card class="h-100 app-local-card" no-body>
            <b-row class="app-local-pointer" @click="getIssuesList()">
                <b-col>
                    <div class="app-local-first-line w-100">
                        <span class="app-local-first-line-span text-center">
                            {{!loading ? actions : 0}}                 
                        </span>
                        <b-icon icon="bell-fill" class="app-local-first-line-icon"/>
                    </div>

                    <div class="w-100 text-center"> 
                        <div class="app-local-second-line w-100 text-center">
                            Acties
                        </div>
                    </div>
                </b-col>
            </b-row>
    </b-card>
</template>

<script>

export default {

    data() {
        return {
        };
    },

    props: {
        actions: {}, 
        loading: {}, 

    },

    mounted(){
    },

    methods: {
        getIssuesList() {
            this.$router.push({ name: "issues-list"});
        },
    }

};
</script>

<style scoped>
.app-local-container {
    display: flex;
    flex-wrap: nowrap;    
}

.app-local-card {   
   background: #066791;
}

.app-local-title-span {
    margin-top: 20px;
    flex: auto;    
}

.app-local-progress {
    max-height: 4px; 
    min-height: 4px;
    margin-bottom: 20px;
}

.app-local-first-line {
    
    margin-top: 10px;
    margin-left: 6px;
    flex: auto;
    color: white;
    display: flex;
    justify-content: center;
}

.app-local-first-line-span {
    align-self: flex-end;
    font-size: 40px;
    font-weight: 600;    
}

.app-local-first-line-icon {
    align-self: flex-start;
    margin-top: 8px;
    margin-left: 4px;
    width: 16px;
    height: 16px;    
}

.app-local-second-line {
    max-height: 20px; 
    min-height: 20px;
    margin-bottom: 20px;
    color: white;
}

.app-local-pointer{
    cursor:pointer;
}

</style>
