<template>
    <div>
         <b-navbar sticky class="app-navbar-profile app-local-margin-left">
            <div class="app-navbar-header">
                <b-row>
                    <b-col cols="8" class="app-navbar-profile-margin-4"></b-col>
                    <b-col end class="app-navbar-profile-margin-30">
                        <div class="d-flex justify-content-end align-items-center">
                           <span class="align-middle text-muted mr-3"> {{ getCurrentDate() }} </span>
                            
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-navbar>
        <div class="app-local-dashboard">
            <b-row align-v="center" class="mb-2">
                <b-col>
                    <div class="ml-2 app-card-profile-title">{{trans('d-hello',201)}}</div>
                </b-col>
                <b-col class="app-input-employee-filter-width app-local-title-height pl-0" end v-if="filters.type != 3">
                <app-select v-model="filters.selected_office" :clearable="false" :selectOnTab="true" :options="office_list" :selectable="(item) => item.value >= 0" >
                            <template v-slot:option="option">
                            <hr v-if="option.data.value < 0" class="app-search-modal-separator">    
                            <span v-else>{{ option.data }}</span>
                            </template>
                        </app-select>  
                </b-col>
                <b-col cols="auto" end>
                    <b-button-group  size="sm">
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 1, 'app-list-button-selected':  filters.type === 1 }" @click="setType(1)">Recruitment</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 2, 'app-list-button-selected':  filters.type === 2 }" @click="setType(2)">Sales</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 3, 'app-list-button-selected':  filters.type === 3 }" v-if="user_role == 1" @click="setType(3)">Admins</b-btn>
                    </b-button-group> 
                </b-col>
                
            </b-row>
        </div>

        <dashboards-recruitment-view v-if="filters.type == 1" :selected_office="filters.selected_office" :settings_loaded="settings_loaded"/>
        <dashboards-sale-view  v-if="filters.type == 2" :selected_office="filters.selected_office" :settings_loaded="settings_loaded"/>
        <dashboards-administration-view  v-if="filters.type == 3" :selected_office="filters.selected_office" :settings_loaded="settings_loaded"/>

    </div>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import DashboardsRecruitmentView from './Components/DashboardsRecruitmentView.vue';
import DashboardsSaleView from './Components/DashboardsSaleView.vue';
import DashboardsAdministrationView from './Components/DashboardsAdministrationView.vue';
import UserSettings from '@core/scripts/UserSettings';

export default {
    components: {
        DashboardsRecruitmentView,
        DashboardsSaleView,
        DashboardsAdministrationView
    },

    watch: {
        "filters": {
            handler: function(val) {
                this.saveSettings();             
            },
            deep:true,
        },
    },
    
   
    data() {
        return {
            first_release: JSON.parse(localStorage.getItem('user')).first_release,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

            office_list: [],
            filters: {
                selected_office: {},
                type: 1,
            },
            user_settings: new UserSettings(),
            settings_loaded: false,
        };
    },
    mounted () {
         this.office_list = JSON.parse(localStorage.getItem("USER_LOCATIONS"));
         this.office_list.splice(0, 0, { value: -1, name: ""});
         this.office_list.splice(0, 0, { value: 0, name: "Alle vestigingen" });
         this.loadSettings();
    },

    methods: {

        getCurrentDate() {
            return moment().format('DD MMM YYYY') + " (wk" + moment().format('WW') + ")"
        },

        setType(type){
            this.filters.type = type;
        },

        saveSettings() {
            this.user_settings.saveSettings(this.filters, 21);
        },

        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));
            
            if(getData == undefined || getData.dashboardOffice == undefined){
                this.filters.selected_office = { value: 0, name: 'Alle vestigingen' }
                this.filters.type = 1;

                await this.user_settings.saveSettings(this.filters, 21);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
              
            var sFilters = getData.dashboardOffice
            this.filters.selected_office = sFilters.selected_office; 
            this.filters.type = sFilters.type; 
            this.settings_loaded = true;
        },
    },
};
</script>

<style scoped>
.app-local-dashboard{
    padding-top: 25px;
    z-index:1;
}

.app-local-margin-left{
    margin-left: -15px;
}

</style>
